<template>

  <!-- Service section  -->
  <div class="service-section pt-12 pb-7 pb-lg-25 pt-lg-19">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8 col-sm-10">
          <div class="section-title text-center mb-11 mb-lg-20">
            <h2 class="title gr-text-4 mb-7">Services we offer for you</h2>
            <p class="gr-text-8 px-lg-7 px-xl-0">
              We build security into everything we do, and offer bespoke cyber security consulting too.</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10 col-sm-6 col-lg-3 mb-9 mb-lg-0" data-aos="fade-left" data-aos-duration="500"
             data-aos-once="true">
          <div class="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
            <img src="/image/l2/jpg/l2-service-img1.jpg" class="card-img-top rounded-top-10" alt="...">
            <div class="card-body bg-white rounded-bottom-10 px-7 py-6">
              <a href="" class="card-title gr-text-9 text-blackish-blue card-btn-link with-icon mb-0">
 Software Quality Assurance<i class="icon icon-tail-right font-weight-bold"></i></a>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-6 col-lg-3 mb-9 mb-lg-0" data-aos="fade-left" data-aos-duration="1100"
             data-aos-once="true">
          <div class="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
            <img src="/image/l2/jpg/l2-service-img2.jpg" class="card-img-top rounded-top-10" alt="...">
            <div class="card-body bg-white rounded-bottom-10 px-7 py-6">
              <a href="" class="card-title gr-text-9 text-blackish-blue card-btn-link with-icon mb-0">       Network Security
                Penetration Testing
             <i class="icon icon-tail-right font-weight-bold"></i></a>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-6 col-lg-3 mb-9 mb-lg-0" data-aos="fade-left" data-aos-duration="1700"
             data-aos-once="true">
          <div class="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
            <img src="/image/l2/jpg/l2-service-img3.jpg" class="card-img-top rounded-top-10" alt="...">
            <div class="card-body bg-white rounded-bottom-10 px-7 py-6">
              <a href="" class="card-title gr-text-9 text-blackish-blue card-btn-link with-icon mb-0">    Managed Security
               <i class="icon icon-tail-right font-weight-bold"></i></a>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-6 col-lg-3 mb-9 mb-lg-0 " data-aos="fade-left" data-aos-duration="2300"
             data-aos-once="true">
          <div class="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
            <img src="/image/l2/jpg/l2-service-img4.jpg" class="card-img-top rounded-top-10" alt="...">
            <div class="card-body bg-white rounded-bottom-10 px-7 py-6">
              <a href="" class="card-title gr-text-9 text-blackish-blue card-btn-link with-icon mb-0">SEO for Business
                <i class="icon icon-tail-right font-weight-bold"></i></a>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-6 col-lg-3 mb-9 mb-lg-0 " data-aos="fade-left" data-aos-duration="2300"
             data-aos-once="true">
          <div class="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
            <img src="/image/l2/jpg/l2-service-img4.jpg" class="card-img-top rounded-top-10" alt="...">
            <div class="card-body bg-white rounded-bottom-10 px-7 py-6">
              <a href="" class="card-title gr-text-9 text-blackish-blue card-btn-link with-icon mb-0"> Red Team Assessments
               <i class="icon icon-tail-right font-weight-bold"></i></a>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-6 col-lg-3 mb-9 mb-lg-0 " data-aos="fade-left" data-aos-duration="2300"
             data-aos-once="true">
          <div class="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
            <img src="/image/l2/jpg/l2-service-img4.jpg" class="card-img-top rounded-top-10" alt="...">
            <div class="card-body bg-white rounded-bottom-10 px-7 py-6">
              <a href="" class="card-title gr-text-9 text-blackish-blue card-btn-link with-icon mb-0">
                Cyber Security Training<i class="icon icon-tail-right font-weight-bold"></i></a>
            </div>
          </div>
        </div>
        <div class="col-10 col-sm-6 col-lg-3 mb-9 mb-lg-0 " data-aos="fade-left" data-aos-duration="2300"
             data-aos-once="true">
          <div class="service-card rounded-10 border border-gray-3 gr-hover-shadow-1">
            <img src="/image/l2/jpg/l2-service-img4.jpg" class="card-img-top rounded-top-10" alt="...">
            <div class="card-body bg-white rounded-bottom-10 px-7 py-6">
              <a href="" class="card-title gr-text-9 text-blackish-blue card-btn-link with-icon mb-0">
                Email Security<i class="icon icon-tail-right font-weight-bold"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Service"
}
</script>

<style scoped>

</style>