<template>
  <!-- Testimonial section -->
  <div class="testimonial-section pt-15 pt-lg-24 pb-lg-12 bg-default-1">
    <div class="container">
      <div class="row align-items-center justify-content-around">
        <div class="col-lg-4 col-md-6 col-sm-9 mb-16">
          <div class="single-testimonial text-center h-100 d-flex flex-column px-5">
            <div class="brand-logo mb-9 mb-lg-13">
              <img src="/image/l2/png/testimonial-brand-logo1.png" alt="">
            </div>
            <p class="review-text mb-0 gr-text-6 font-weight-bold gr-text-color">“The team supported us during deployment of our cloud services and developed best security policies for us.”</p>
            <div class="user-block media pt-9 pt-lg-12 d-flex justify-content-center mt-auto">
              <div class="user-image circle-md mr-7">
                <img class="w-100" src="/image/l2/png/l2-testimonial1.png" alt="">
              </div>
              <div class="user-text text-left align-self-center">
                <h4 class="user-title gr-text-9 mb-0">Ilya Vasin</h4>
                <span class="user-rank gr-text-11 gr-text-color-opacity">Software Engineer</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-9 mb-16">
          <div class="single-testimonial text-center h-100 d-flex flex-column px-5">
            <div class="brand-logo mb-9 mb-lg-13">
              <img src="/image/l2/png/testimonial-brand-logo2.png" alt="">
            </div>
            <p class="review-text mb-0 gr-text-6 font-weight-bold gr-text-color">“They are very good at finding bugs and loopholes in our company.We are now feel more safe”</p>
            <div class="user-block media pt-9 pt-lg-12 d-flex justify-content-center mt-auto">
              <div class="user-image circle-md mr-7">
                <img class="w-100" src="/image/l2/png/l2-testimonial2.png" alt="">
              </div>
              <div class="user-text text-left align-self-center">
                <h4 class="user-title gr-text-9 mb-0">Mariano Rasgado</h4>
                <span class="user-rank gr-text-11 gr-text-color-opacity">PataPawa</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-9 mb-16">
          <div class="single-testimonial text-center h-100 d-flex flex-column px-5">
            <div class="brand-logo mb-9 mb-lg-13">
              <img src="/image/l2/png/testimonial-brand-logo1.png" alt="">
            </div>
            <p class="review-text mb-0 gr-text-6 font-weight-bold gr-text-color">“They have been very reponsive in business continuation after our recent malware attack.”</p>
            <div class="user-block media pt-9 pt-lg-12 d-flex justify-content-center mt-auto">
              <div class="user-image circle-md mr-7">
                <img class="w-100" src="/image/l2/png/l2-testimonial3.png" alt="">
              </div>
              <div class="user-text text-left align-self-center">
                <h4 class="user-title gr-text-9 mb-0">Oka Tomoaki</h4>
                <span class="user-rank gr-text-11 gr-text-color-opacity">Head of IT</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
name: "Testimonial"
}
</script>

<style scoped>

</style>