<template>
  <div class="newsltr-section pt-12 pb-10 pt-lg-19 pb-lg-17 bg-default-6 border-bottom ">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 pr-lg-9 col-md-10">
          <div class="nwsltr-text">
            <h2 class="title gr-text-5">Need a Quote? Requesting <a href=""></a> service or need assistance.</h2>
          </div>
        </div>
        <div class="col-lg-6 pl-xl-13 col-md-10">
          <form class="newsletter-form d-flex justify-content-lg-end mt-6 mt-lg-0">
            <div class="form-group mr-7 w-100">
              <input type="email" class="form-control" id="leadEmail1" placeholder="Enter your email">
            </div>
            <div class="cta-btn">
              <button type="submit" class="btn btn-primary with-icon gr-hover-y">Submit<i
                  class="icon icon-tail-right"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
name: "Newsletter"
}
</script>

<style scoped>

</style>