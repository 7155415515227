<template>
  <!-- Hero Area -->
  <div class="bg-image bg-gradient bg-gradient-black pb-17 pt-24 pt-lg-32 pb-lg-28"
       style="background-image:url(image/l2/jpg/l2-hero-bg.jpg);">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-9 col-lg-7 col-xl-6" data-aos="fade-right" data-aos-duration="500" data-aos-once="true">
          <div class="hero-content section-title dark-mode-texts">
            <h1 class="gr-text-2 font-weight-bold mb-8">InfoSec Advisory . Defence . Training</h1>
            <p class="gr-text-8 pr-sm-7 pr-md-12">A full-service cyber security firm, dedicated to providing first-rate, world class cyber security solutions for enterprise, intelligence and governments.</p>
            <div class="hero-btns mt-10">
              <a href="#" class="btn btn-primary with-icon gr-hover-y">Request Quote<i
                  class="icon icon-tail-right font-weight-bold"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Hero"
}
</script>

<style scoped>

</style>