<template>
  <div>
    <!-- Header Area -->
    <Header></Header>
    <!-- navbar-dark -->
    <!-- Hero Area -->
    <Hero></Hero>
    <!-- Fact Counter section -->
    <Fact></Fact>
    <!-- Service section  -->
    <Service></Service>
    <!-- Content section 2 with video -->
<!--    <VideoContent></VideoContent>-->
    <!-- Alert section -->
    <div class="alert-section py-9 bg-primary dark-mode-texts ">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-7 col-lg-8 col-md-9">
            <div
                class="alert-content d-flex flex-column flex-lg-row align-items-center text-center justify-content-lg-center">
              <span
                  class="btn-badge rounded-pill bg-white gr-text-12 text-uppercase font-weight-bold text-blue py-1 px-6 mr-5 mb-6 mb-lg-0 d-inline-flex align-items-center">Opening</span>
              <span class="alert-text gr-text-9 text-white">We are hiring Ethical Hackers send your CV to <a href="mail:info@inkala.co.ke"
                                                                                                           class="action-link text-white gr-text-underline">info@inkala.co.ke.</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Testimonial section -->
    <Testimonial></Testimonial>
    <!-- CTA section -->
    <CTA></CTA>
    <!-- Newsletter section -->
    <Newsletter></Newsletter>
    <!-- Footer section -->
    <Footer></Footer>

  </div>
</template>


<script>
import Header from "@/components/Header";
import Hero from "@/components/Hero";
import Fact from "@/components/Fact";
import Service from "@/components/Service";
// import VideoContent from "@/components/VideoContent";
import Testimonial from "@/components/Testimonial";
import CTA from "@/components/CTA";
import Newsletter from "@/components/Newsletter";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Newsletter,
    CTA,
    Testimonial,
    // VideoContent,
    Service,
    Footer,
    Fact,
    Hero,
    Header
  },
  props: {
    msg: String,
    hero: String
  }
}
</script>

<style>
.main-menu > li > .nav-link{
  /*color: black!important;*/
}
</style>
