<template>
  <div class="footer-section bg-default-6">
    <div class="container">
      <div class="footer-top pt-15 pt-lg-25 pb-lg-19">
        <div class="row">
          <div class="col-6 col-lg-2">
            <div class="single-footer mb-13 mb-lg-9">
              <p class="footer-title gr-text-11 mb-7">Company</p>
              <ul class="footer-list list-unstyled">
                <li class="py-2"><a class="gr-text-9 gr-text-color" href="">About us</a></li>
                <li class="py-2"><a class="gr-text-9 gr-text-color" href="">Contact us</a></li>
                <li class="py-2"><a class="gr-text-9 gr-text-color" href="">Careers</a></li>
                <li class="py-2"><a class="gr-text-9 gr-text-color" href="">Press</a></li>
              </ul>
            </div>
          </div>
          <div class="col-6 col-lg-2">
            <div class="single-footer mb-13 mb-lg-9">
              <p class="footer-title gr-text-11 mb-7">Services</p>
              <ul class="footer-list list-unstyled">
                <li class="py-1"><a class="gr-text-9 gr-text-color" href="">Software Quality Assurance</a></li>
                <li class="py-1"><a class="gr-text-9 gr-text-color" href="">Content Writing</a></li>
                <li class="py-1"><a class="gr-text-9 gr-text-color" href="">Penetration Testing</a></li>
                <li class="py-1"><a class="gr-text-9 gr-text-color" href="">Red Team Assessments</a></li>
                <li class="py-1"><a class="gr-text-9 gr-text-color" href="">Content Writing</a></li>
                <li class="py-1"><a class="gr-text-9 gr-text-color" href="">Cyber Security Training</a></li>
                <li class="py-1"><a class="gr-text-9 gr-text-color" href="">SEO for Business</a></li>
              </ul>
            </div>
          </div>
          <div class="col-6 col-lg-3 pl-lg-15">
            <div class="single-footer mb-13 mb-lg-9">
              <p class="footer-title gr-text-11 mb-7">Legal</p>
              <ul class="footer-list list-unstyled">
                <li class="py-2"><a class="gr-text-9 gr-text-color" href="">Privacy Policy</a></li>
                <li class="py-2"><a class="gr-text-9 gr-text-color" href="">Terms & Conditions</a></li>
                <li class="py-2"><a class="gr-text-9 gr-text-color" href="">Return Policy</a></li>
              </ul>
            </div>
          </div>
          <div class="col-6 col-lg-3">
            <div class="single-footer mb-13 mb-lg-9">
              <p class="footer-title gr-text-11 mb-7">Contact us</p>
              <ul class="footer-list list-unstyled">
                <li class="py-2">
                  <a class="gr-text-9 text-primary font-weight-bold hover-underline active"
                     href="mailto:info@tonfa.co.ke"> info@tonfa.co.ke </a>
                  <a class="gr-text-9 text-primary font-weight-bold hover-underline active"
                     href="mailto:info@inkala.co.ke"> info@inkala.co.ke </a>
                </li>
                <li class="py-2">
                  <a class="gr-text-9 text-primary font-weight-bold hover-underline active"
                     href="tel:+254 713 835028">+254 713 835028</a><br>
                  <a class="gr-text-9 text-primary font-weight-bold hover-underline active"
                     href="tel:+2547 16610251">+2547 16610251</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-area border-top pt-9 pb-8">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <a  href="//inkala.co.ke" class="copyright-text gr-text-11 mb-6 mb-lg-0 gr-text-color text-center text-lg-left">
              © 2020 Copyright, All Right Reserved, Made by Inkala with
              <i class="icon icon-heart-2-2 text-green align-middle ml-2"></i>
            </a>
          </div>
          <div class="col-lg-6 text-center text-lg-right">
            <ul class="social-icons list-unstyled mb-0">
              <li class="ml-7"><a href="" class="gr-text-color-opacity"><i class="icon icon-logo-twitter"></i></a>
              </li>
              <li class="ml-7"><a href="" class="gr-text-color-opacity"><i class="icon icon-logo-facebook"></i></a>
              </li>
              <li class="ml-7"><a href="" class="gr-text-color-opacity"><i class="icon icon-instant-camera-2"></i></a>
              </li>
              <li class="ml-7"><a href="" class="gr-text-color-opacity"><i class="icon icon-logo-linkedin"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>