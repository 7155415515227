<template>
  <!-- Fact Counter section -->
  <div class="fact-section pt-12 pb-6 pt-lg-19 pb-lg-17 border-bottom border-gray-3 bg-default-6 ">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-6 mb-9 mb-lg-0">
          <div class="single-fact d-flex px-md-5 px-lg-2 px-xl-9">
            <h3 class="gr-text-3 mr-9">1K+</h3>
            <p class="gr-text-9 mb-0">Advisories to our clients based on their stack.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 mb-9 mb-lg-0">
          <div class="single-fact d-flex px-md-5 px-lg-2 px-xl-9">
            <h3 class="gr-text-3 mr-9"><span class="counter">86</span>%</h3>
            <p class="gr-text-9 mb-0">Attaining High Severity CVSS on site.</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 mb-9 mb-lg-0">
          <div class="single-fact d-flex px-md-5 px-lg-2 px-xl-9">
            <h3 class="gr-text-3 mr-9"><span class="counter">4</span></h3>
            <p class="gr-text-9 mb-0">Number of Corporations/Parastatals working with us.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Fact"
}
</script>

<style scoped>

</style>