<template>
  <!-- Header Area -->
  <header class="site-header site-header--menu-left dark-mode-texts site-header--absolute">
    <div class="container-fluid pr-lg-9 pl-lg-9">
      <nav class="navbar site-navbar offcanvas-active navbar-expand-lg  px-0">
        <!-- Brand Logo-->
        <div class="brand-logo"><a href="#">
          <!-- Dark version logo (logo must be White)-->
<!--          <img src="image/logo-main-white.png" alt="" class="dark-version-logo">-->
          <!-- light version logo (logo must be black)-->
<!--          <img src="image/logo-main-black.png" alt="" class="light-version-logo ">-->
          <h4 class="font-weight-bolder text-sky-blue">Inkala.Security</h4>
        </a></div>
        <div class="collapse navbar-collapse" id="mobile-menu">
          <div class="navbar-nav-wrapper">
            <ul class="navbar-nav main-menu">
              <li class="nav-item">
                <a class="nav-link" href="https://uxtheme.net/product-support/" role="button"
                   aria-expanded="false">Support</a>
              </li>
            </ul>
          </div>
          <button class="d-block d-lg-none offcanvas-btn-close" type="button" data-toggle="collapse"
                  data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="true"
                  aria-label="Toggle navigation">
            <i class="gr-cross-icon"></i>
          </button>
        </div>
        <div class="header-btn  ml-auto mr-6 mr-lg-0 d-none d-xs-block">
          <a class="btn btn-primary gr-text-9" href="mailto:info@inkala.co.ke">
            Contact Us
          </a>
        </div>
        <!-- Mobile Menu Hamburger-->
        <button class="navbar-toggler btn-close-off-canvas  hamburger-icon border-0" type="button"
                data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="false"
                aria-label="Toggle navigation">
          <!-- <i class="icon icon-simple-remove icon-close"></i> -->
          <span class="hamburger hamburger--squeeze js-hamburger">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </span>
        </button>
        <!--/.Mobile Menu Hamburger Ends-->
      </nav>
    </div>
  </header>
</template>

<script>
export default {
name: "Header"
}
</script>

<style scoped>

</style>